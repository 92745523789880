import { useEffect } from 'react';
import debounce from 'lodash/debounce';

export default function useWindowResize(handler: () => void) {
  useEffect(() => {
    const handleResize = debounce(() => {
      handler();
    }, 300);

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);
}
